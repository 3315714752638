export const EversoulSpecialitiesArray = [
  { label: 'Any Speciality', value: 'all', description: '' },
  {
    label: 'Accuracy/Evasion Down',
    value: 'Acc/Eva Down',
    description: 'Decreases enemies Accuracy/Evasion stat.'
  },
  {
    label: 'Anti-Heal',
    value: 'Anti Heal',
    description: 'Decreases the amount of healing enemies receive.'
  },
  {
    label: 'Attack Down',
    value: 'Attack Down',
    description: 'Decreases enemies Attack stat.'
  },
  {
    label: 'Cleanse',
    value: 'Cleanse',
    description: 'Removes debuffs from allies.'
  },
  {
    label: 'Damage Increase',
    value: 'Damage Increase',
    description:
      'Increases Damage (Generic/Specific) dealt by allies or received by enemies.'
  },
  {
    label: 'Damage Reduction',
    value: 'Damage Reduction',
    description:
      'Decreases Damage (Generic/Specific) dealt by enemies or received by allies.'
  },
  {
    label: 'Defense Down',
    value: 'Defense Down',
    description: 'Decreases enemies Defense stat.'
  },
  {
    label: 'Dispel',
    value: 'Dispel',
    description: 'Removes buffs from enemies.'
  },
  {
    label: 'Damage over Time (Bleed and Shock)',
    value: 'DoT Bleed/Shock',
    description: 'Deals Damage over Time using Bleed or Shock effect.'
  },
  {
    label: 'Damage over Time (Others)',
    value: 'DoT Others',
    description: 'Deals Damage over Time using Curse/Freeze/Burn/Poison effect.'
  },
  {
    label: 'Magical/Physical Resistance Down',
    value: 'M.Res/ P.Res Down',
    description: 'Decreases enemies Magical/Physical Resistance stat.'
  },
  {
    label: 'Mana Reduction/Drain',
    value: 'Mana Drain',
    description:
      'Decreases enemies Mana, prevents enemies from recovering it or reduces its recovery rate.'
  },
  {
    label: 'Mana Gain',
    value: 'Mana Gain',
    description:
      'Restores Mana for allies or self or increases its recovery rate.'
  },
  {
    label: 'Crowd Control (Non Stun)',
    value: 'Other CC',
    description:
      'Includes Crowd Control effects such as Silence, Charm, Sleep and Bind.'
  },
  {
    label: 'Shield (Allies)',
    value: 'Shield (Allies)',
    description: 'Supports allies with shields.'
  },
  {
    label: 'Speed Buff (Allies)',
    value: 'Speed Buff (Allies)',
    description: 'Increases Speed stat of allies.'
  },
  {
    label: 'Speed Down',
    value: 'Speed Down',
    description: 'Decreases enemies Speed stat.'
  },
  {
    label: 'Stun (Sub and Ultimate)',
    value: 'Stun',
    description: 'Crowd Control using Stun from Sub Skills and Ultimate Skill.'
  },
  {
    label: 'Stun (Main)',
    value: 'Stun on Demand',
    description: 'Crowd Control using Stun from Main Skill.'
  },
  {
    label: 'Taunt',
    value: 'Taunt',
    description: 'Forces enemies to attack the character.'
  }
];
