import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import { IEversoulCharacter } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import { Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { EversoulCharacter } from '../../modules/es/common/components/eversoul-character';
import { EversoulSpecialitiesArray } from '../../modules/es/character/model/eversoul-specialities-array';

interface IEversoulCharacterNodes {
  nodes: IEversoulCharacter[];
}

interface IEversoulCharacterEntry {
  allCharacters: IEversoulCharacterNodes;
}

interface IProps {
  data: IEversoulCharacterEntry;
}

const EsCharactersPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Human',
            tooltip: 'Human',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_human.png"
                width={24}
                alt="Human"
              />
            )
          },
          {
            value: 'Beast',
            tooltip: 'Beast',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_beast.png"
                width={24}
                alt="Beast"
              />
            )
          },
          {
            value: 'Fairy',
            tooltip: 'Fairy',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_fairy.png"
                width={24}
                alt="Fairy"
              />
            )
          },
          {
            value: 'Undead',
            tooltip: 'Undead',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_undead.png"
                width={24}
                alt="Undead"
              />
            )
          },
          {
            value: 'Angel',
            tooltip: 'Angel',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_angel.png"
                width={24}
                alt="Angel"
              />
            )
          },
          {
            value: 'Demon',
            tooltip: 'Demon',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_demon.png"
                width={24}
                alt="Demon"
              />
            )
          },
          {
            value: 'Chaotic',
            tooltip: 'Chaotic',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/type_chaotic.png"
                width={24}
                alt="Chaotic"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'caster',
            tooltip: 'Caster',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_caster.png"
                width={24}
                alt="Caster"
              />
            )
          },
          {
            value: 'defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_defender.png"
                width={24}
                alt="Defender"
              />
            )
          },
          {
            value: 'ranger',
            tooltip: 'Ranger',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_ranger.png"
                width={24}
                alt="Ranger"
              />
            )
          },
          {
            value: 'striker',
            tooltip: 'Striker',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_striker.png"
                width={24}
                alt="Striker"
              />
            )
          },
          {
            value: 'supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_supporter.png"
                width={24}
                alt="Supporter"
              />
            )
          },
          {
            value: 'warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/eversoul/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          }
        ]
      },
      {
        key: 'specialities',
        type: 'dropdown',
        values: EversoulSpecialitiesArray
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
      if (activeFilters.specialities && activeFilters.specialities !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.specialities &&
            emp.specialities.length > 0 &&
            emp.specialities.indexOf(activeFilters.specialities) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.slug < b.slug ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page characters-page-nikke'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul">Eversoul </Link>
        </li>
        <li className="divider">/</li>
        <li>Characters</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/eversoul/categories/category_characters.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details ">
          <h1>Eversoul Characters</h1>
          <h2>List of characters available in Eversoul.</h2>
          <p>
            Last updated: <strong>31/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar eversoul">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className={`employees-container`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <EversoulCharacter
                  slug={emp.slug}
                  mode="icon"
                  showLabel
                  showTags
                  enablePopover
                />
              </Card>
            </span>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/counterside/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default EsCharactersPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters | Eversoul | Prydwen Institute"
    description="List of characters (Souls) available in Eversoul."
    game="eversoul"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulEversoulCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        updatedAt
        createdAt
        unitId
        name
        slug
        rarity
        type
        class
        specialities
        isNew
        isReviewPending
        futureCharacter
        isUpdated
      }
    }
  }
`;
